import React, { useState } from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"

import Footer from "../components/footer"
import Menu from "src/components/moreMenu"

import * as launcherStyles from "./launchers.module.css"

import { OutboundLink } from "gatsby-plugin-google-gtag"

import NickelledLogo from "../images/svgs/nickelled-logo-o.svg"

import onpageLauncher from "../images/launchers/on-page-launcher.svg"
import launcherOptions from "../images/launchers/launcher-options.png"
import offsiteLauncher from "../images/launchers/off-site-launcher.svg"
import iframeEmbed from "../images/launchers/iframe-embed.svg"
import googleTagManager from "../images/launchers/google-tag-manager.svg"

import waveBg from "../images/launchers/wave.svg"

import launchersBg from "../images/launchers/top-banner.svg"
import missionDock from "../images/launchers/mission-dock.svg"
import missionLauncher from "../images/launchers/mission-launcher.svg"
import announcementBar from "../images/launchers/announcement.svg"
import launchButton from "../images/launchers/intelligent-launch-button.svg"
import customButton from "../images/launchers/launch-button.svg"

export default function LaunchersPage() {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <>
      <SEO title="Nickelled Launchers" />
      <script
        dangerouslySetInnerHTML={{
          __html: `(function() {var NickelledLaunchers = window.NickelledLaunchers = NickelledLaunchers||{setUser:function(u){this.userData=u}};let r = Math.random().toString(36).substring(7);NickelledLaunchers.userData = {
                appId: "nickelledmarketingsite-94848",
                userId: r
                };
                (function(){var s,f;s = document.createElement("script");
                s.async = true;s.src = "https://cdn.nickelled.com/v3/embed.js";
                f = document.getElementsByTagName("script")[0];f.parentNode.insertBefore(s,f);})(); })();
                window.NickelledLaunchers.onGuideShow = function(guideDetails) {
                console.log("Guide show has been triggered!");
                }`,
        }}
      ></script>
      <div
        class="relative bg-silver"
        style={{
          backgroundImage: `url(${launchersBg})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "right",
        }}
      >
        <div class="relative pt-6 pb-16 sm:pb-24 px-4 md:px-0">
          <div class="max-w-7xl mx-auto">
            <nav
              class="relative flex items-center justify-between sm:h-10 md:justify-center"
              aria-label="Global"
            >
              <div class="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                <div class="flex items-center justify-between w-full md:w-auto">
                  <a href="https://www.nickelled.com">
                    <span class="sr-only">Nickelled</span>
                    <NickelledLogo class="h-8 w-auto sm:h-10"></NickelledLogo>
                  </a>
                  <div class="-mr-2 flex items-center md:hidden">
                    <button
                      type="button"
                      class="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                      id="main-menu"
                      aria-haspopup="true"
                      onClick={() => toggleExpansion(isExpanded)}
                    >
                      <span class="sr-only">Open main menu</span>
                      <svg
                        class="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <div class="hidden md:flex md:space-x-10">
                  <a
                    href="/request-demo/"
                    class="font-medium text-gray-500 hover:text-gray-900"
                  >
                    Request demo
                  </a>

                  <a
                    href="/pricing"
                    class="font-medium text-gray-500 hover:text-gray-900"
                  >
                    Pricing
                  </a>

                  <Menu />

                  <a
                    href="https://dashboard.nickelled.com/account/login"
                    class="font-medium text-gray-500 hover:text-gray-900"
                  >
                    Sign In
                  </a>
                </div>
                <div class="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
                  <span class="inline-flex rounded-md shadow">
                    <OutboundLink
                      href="https://app.nickelled.com/account/signup"
                      class="inline-flex items-center px-4 py-2 rounded-md text-white font-semibold bg-gradient-to-r from-oRed to-oOrange"
                    >
                      Free Trial
                    </OutboundLink>
                  </span>
                </div>
              </div>
            </nav>
          </div>
          {
            //Mobile menu, show/hide based on menu open state.
            //Entering: "duration-150 ease-out"
            //From: "opacity-0 scale-95"
            //To: "opacity-100 scale-100"
            //Leaving: "duration-100 ease-in"
            ///From: "opacity-100 scale-100"
            //To: "opacity-0 scale-95"
          }
          <div
            className={
              "absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden scale-95 " +
              (isExpanded
                ? "opacity-100 duration-150 ease-out scale-100"
                : "opacity-0 duration-100 ease-in scale-95")
            }
          >
            <div class="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div class="px-5 pt-4 flex items-center justify-between">
                <div>
                  <NickelledLogo class="h-8 w-auto"></NickelledLogo>
                </div>
                <div class="-mr-2">
                  <button
                    type="button"
                    class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange"
                    onClick={() => toggleExpansion(!isExpanded)}
                  >
                    <span class="sr-only">Close menu</span>
                    <svg
                      class="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="main-menu"
              >
                <div class="px-2 pt-2 pb-3" role="none">
                  <a
                    href="/request-demo/"
                    class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    role="menuitem"
                  >
                    Request demo
                  </a>

                  <Link
                    to="/pricing/"
                    class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    role="menuitem"
                  >
                    Pricing
                  </Link>

                  <a
                    href="/case-studies/"
                    class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    role="menuitem"
                  >
                    Case Studies
                  </a>
                  <a
                    href="/saas-resources/"
                    class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    role="menuitem"
                  >
                    SaaS Resources
                  </a>
                </div>
                <div role="none">
                  <OutboundLink
                    href="https://app.nickelled.com/account/signup"
                    class="block w-full px-5 py-3 text-center font-medium text-oOrange bg-gray-50 hover:bg-gray-100"
                    role="menuitem"
                  >
                    Free Trial
                  </OutboundLink>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-16 mx-auto max-w-7xl sm:mt-24">
            <div class="text-left w-1/2 my-16">
              <h1 class="text-4xl text-oGrey font-semibold">
                <span class="block xl:inline">Creating a </span>
                <span class="block text-oOrange xl:inline">
                  beautiful guided tour{" "}
                </span>
                <span class="block xl:inline">
                  is a waste of time if nobody sees it
                </span>
              </h1>
              <p class="mt-3 max-w-md text-base text-gray-800 sm:text-lg md:mt-8 md:text-xl md:max-w-3xl">
                Nickelled launchers make it easy to get your guided tours in
                front of your users. Read on to find out more about the on-page
                launchers and off-site launchers you can use with Nickelled.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="max-w-7xl mx-auto py-16">
        <div class="lg:grid lg:grid-cols-12 lg:gap-x-8 gap-y-36 items-center">
          <div class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
              On-page launchers
            </h2>
            <p class="mt-4 text-lg text-gray-500">
              Nickelled on-page launchers can be used to display guided tours
              anywhere on your site. Your Nickelled guided tour will open up
              over the top of the page, close automatically once the guide is
              complete, and can easily be exited by the user by clicking the
              close button at the top right-hand side.
            </p>
          </div>
          <div class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <img class="w-full p-16" src={onpageLauncher} alt="" />
          </div>
          <div class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <img class="w-full p-16" src={launcherOptions} alt="" />
          </div>
          <div class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
              Advanced Filtering
            </h2>
            <p class="mt-4 text-lg text-gray-500">
              What best practices do companies need to ensure that every user
              has a great onboarding experience, every time?
            </p>
            <p class="mt-4 text-lg text-gray-500">
              Our on-page javascript snippet can accept user IDs, user
              properties and page locations in order to determine which
              launchers should be shown where. Use built-in logic such as show
              the guides for teachers on the page 'teacher-homepage' for maximum
              simplicity.
            </p>
            <p class="mt-4 text-lg text-gray-500">
              For advanced use cases such as show to users more than three days
              old with an paid flag, stack your own rules and pass us custom
              user data.
            </p>
          </div>
        </div>
      </div>
      <div>
        <div
          class="w-full py-4"
          style={{
            backgroundImage: `url(${waveBg})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div class="max-w-7xl mx-auto py-16">
            <div class="w-full my-8 text-center">
              <h3 class="text-3xl font-extrabold tracking-tight text-gray-900">
                {" "}
                Easy ways to launch from your site{" "}
              </h3>
              <p class="mt-4 text-lg">
                All Nickelled guided tours can be launched from the page. Click
                a launcher to see it in action.
              </p>
            </div>
            <div class="w-100 flex flex-row justify-between align-stretch mb5 pt4">
              <Link
                to="#mission-dock"
                id="missionDockLauncher"
                className={
                  launcherStyles.launcherPanel +
                  " rounded-xl shadow-md bg-white text-white overflow-hidden block no-underline cursor-pointer"
                }
              >
                <header
                  className={
                    launcherStyles.header +
                    " rounded-xl w-full text-center overflow-hidden relative flex justify-center"
                  }
                >
                  <div
                    className={
                      launcherStyles.header__bg + " bg-navy mb-3 absolute"
                    }
                  ></div>
                  <div
                    className={
                      launcherStyles.header__img +
                      " bg-white block mt-3 absolute z-10"
                    }
                  >
                    <img src={missionDock} />
                  </div>
                </header>
                <div class="px-3 pb-3 pt-2 text-center">
                  <h3 class="text-sm font-bold text-navy pb-2 my-0">
                    Mission Dock
                  </h3>
                  <p class="text-sm lh-copy text-navy py-0 my-0">
                    Show a sequence of guides for people to follow on your
                    website
                  </p>
                </div>
              </Link>

              <a
                className={
                  launcherStyles.launcherPanel +
                  " rounded-xl shadow-md bg-white text-white overflow-hidden block no-underline cursor-pointer"
                }
                href="https://sandbox-site.nickelled.com/welcome.html"
                rel="nofollow"
                target="_blank"
              >
                <header
                  className={
                    launcherStyles.header +
                    " rounded-xl w-full text-center overflow-hidden relative flex justify-center"
                  }
                >
                  <div
                    className={
                      launcherStyles.header__bg + " bg-navy mb-3 absolute"
                    }
                  ></div>
                  <div
                    className={
                      launcherStyles.header__img +
                      " bg-white block mt-3 absolute z-10"
                    }
                  >
                    <img src={missionLauncher} />
                  </div>
                </header>
                <div class="px-3 pb-3 pt-2 text-center">
                  <h3 class="text-sm font-bold text-navy pb-2 my-0">
                    Autoplay Window
                  </h3>
                  <p class="text-sm lh-copy text-navy py-0 my-0">
                    Show a popup window when your website loads
                  </p>
                </div>
              </a>

              <Link
                to="#notification-bar"
                id="notificationBarLauncher"
                className={
                  launcherStyles.launcherPanel +
                  " rounded-xl shadow-md bg-white text-white overflow-hidden block no-underline cursor-pointer"
                }
              >
                <header
                  className={
                    launcherStyles.header +
                    " rounded-xl w-full text-center overflow-hidden relative flex justify-center"
                  }
                >
                  <div
                    className={
                      launcherStyles.header__bg + " bg-navy mb-3 absolute"
                    }
                  ></div>
                  <div
                    className={
                      launcherStyles.header__img +
                      " bg-white block mt-3 absolute z-10"
                    }
                  >
                    <img src={announcementBar} />
                  </div>
                </header>
                <div class="px-3 pb-3 pt-2 text-center">
                  <h3 class="text-sm font-bold text-navy pb-2 my-0">
                    Announcement Bar
                  </h3>
                  <p class="text-sm lh-copy text-navy py-0 my-0">
                    Launch guides from a handy bar at the top of the screen
                  </p>
                </div>
              </Link>

              <Link
                to="#pulse-button"
                id="pulseButtonLauncher"
                className={
                  launcherStyles.launcherPanel +
                  " rounded-xl shadow-md bg-white text-white overflow-hidden block no-underline cursor-pointer"
                }
              >
                <header
                  className={
                    launcherStyles.header +
                    " rounded-xl w-full text-center overflow-hidden relative flex justify-center"
                  }
                >
                  <div
                    className={
                      launcherStyles.header__bg + " bg-navy mb-3 absolute"
                    }
                  ></div>
                  <div
                    className={
                      launcherStyles.header__img +
                      " bg-white block mt-3 absolute z-10"
                    }
                  >
                    <img src={launchButton} />
                  </div>
                </header>
                <div class="px-3 pb-3 pt-2 text-center">
                  <h3 class="text-sm font-bold text-navy pb-2 my-0">
                    Pulse Button
                  </h3>
                  <p class="text-sm lh-copy text-navy py-0 my-0">
                    Highlight functionality with a pulsing question mark
                  </p>
                </div>
              </Link>

              <a
                className={
                  launcherStyles.launcherPanel +
                  " rounded-xl shadow-md bg-white text-white overflow-hidden block no-underline cursor-pointer"
                }
              >
                <header
                  className={
                    launcherStyles.header +
                    " rounded-xl w-full text-center overflow-hidden relative flex justify-center"
                  }
                >
                  <div
                    className={
                      launcherStyles.header__bg + " bg-navy mb-3 absolute"
                    }
                  ></div>
                  <div
                    className={
                      launcherStyles.header__img +
                      " bg-white block mt-3 absolute z-10"
                    }
                  >
                    <img src={customButton} />
                  </div>
                </header>
                <div class="px-3 pb-3 pt-2 text-center">
                  <h3 class="text-sm font-bold text-navy pb-2 my-0">
                    Custom Button
                  </h3>
                  <p class="text-sm lh-copy text-navy py-0 my-0">
                    Turn any button on your website into a guide launcher
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="max-w-7xl mx-auto py-16">
        <div class="lg:grid lg:grid-cols-12 lg:gap-x-8 gap-y-36 items-center">
          <div class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
              Off-site launchers
            </h2>
            <p class="mt-4 text-lg text-gray-500">
              Want to send your Nickelled guide to somebody who's not registered
              for your app, or not logged in? Or looking for the lowest-hassle
              way to get your guides in the hands of users (no plugins, no
              installation required!) immediately? Nickelled guides can also be
              accessed outside of your site, reducing user friction to zero.
            </p>
          </div>
          <div class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <img class="w-full p-16" src={offsiteLauncher} alt="" />
          </div>
          <div class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <img class="w-full p-16" src={iframeEmbed} alt="" />
          </div>
          <div class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
              Iframe embed
            </h2>
            <p class="mt-4 text-lg text-gray-500">
              Want to include a Nickelled guide on a helpdesk, Wordpress site,
              intranet resources or some other external site? Iframe embeds load
              a neat guide preview and one-click launcher and can be pasted into
              any third-party site. And because the embed is loaded directly
              from our servers, it will always show the most up-to-date guide
              title and thumbnail.
            </p>
          </div>
          <div class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
              Using Nickelled with Google Tag Manager
            </h2>
            <p class="mt-4 text-lg text-gray-500">
              Nickelled launchers are fully compatible with Google Tag Manager
              (and other popular container tag solutions) – if you're using a
              tool like this, you'll be able to launch guides inside your app
              with no code changes. Need technical help in setting things up?
              Let us know.
            </p>
          </div>
          <div class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <img class="w-full p-16" src={googleTagManager} alt="" />
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  )
}
